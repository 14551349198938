<template>
    <v-container fluid v-if="Number(params.persona_id)">
        <v-tabs grow>
            <v-tabs-slider></v-tabs-slider>
            <v-tab :href="`#tab_datos_personales`">
                Datos Personales
            </v-tab>

            <v-tab :href="`#tab_datos_accesos`" v-if="permisos[6]">
                Credenciales de Acceso
            </v-tab>

            <v-tab :href="`#tab_permisos_paginas`" v-if="permisos[7]">
                Permisos Páginas
            </v-tab>

            <v-tab :href="`#tab_permisos_unidades_operativas`" v-if="permisos[7]">
                Permisos Unidades Operativas
            </v-tab>

            <v-tab-item :value="'tab_datos_personales'">
                <Formulario :getParams="this.params"></Formulario>
            </v-tab-item>

            <v-tab-item :value="'tab_datos_accesos'">
                <Acceso :getParams="this.params"></Acceso>
            </v-tab-item>

            <v-tab-item :value="'tab_permisos_paginas'">
                <PermisoPaginas :getParams="this.params"></PermisoPaginas>
            </v-tab-item>

            <v-tab-item :value="'tab_permisos_unidades_operativas'">
                <PermisoUnidadesOperativas :getParams="this.params"></PermisoUnidadesOperativas>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
const Formulario = () => import("./components/Formulario.vue");
const Acceso = () => import('./components/Acceso.vue');
const PermisoPaginas = () => import('./components/PermisoPaginas.vue');
const PermisoUnidadesOperativas = () => import('./components/PermisoUnidadesOperativas.vue');
import Auth from "@/services/auth";

export default {
    name: 'ParticipantesAdministradoresEditar',
    data() {
        return {
            permisos: [],
            loading: true,
            params: {
                persona_id: null,
                cat_tipo_persona_id: 2,
            }
        }
    },
    components: {
        Formulario,
        Acceso,
        PermisoPaginas,
        PermisoUnidadesOperativas
    },
    methods: {
        verificarToken() {

            if (typeof this.$route.query.token !== "undefined") {
                try {
                    let token = this.$route.query.token;
                    this.params.persona_id = window.atob(token);
                } catch (e) {
                    console.log(e);
                    this.$toast.error("El parametro para Editar no es valido.");
                    this.regresar();
                }

            } else {
                this.$toast.error("Debe enviar un parametro para Editar.");
                this.regresar();
            }
        },
        regresar() {
            this.$router.go(-1)
        }
    },
    created() {
        Auth.checkPermisos("/participantes/administradores").then((res) => {
            this.permisos = res;

            if (!this.permisos[3]) {
                this.regresar();
            }

            // Verificar los datos del parametro
            this.verificarToken();
        });

    }
}
</script>

<style>

</style>
